import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useForm } from "react-hook-form"
import { connect } from "react-redux"
import * as actions from "@redux/actions"
import { useLocation } from "@reach/router"
import queryString from "query-string"

import Layout from "@components/Layout"
import SEO from "@components/Seo"
import { H1, H3 } from "@components/StyledElements"
import { Section, Label, Input, Button, Error } from "@components/Form"
import Loader from "@components/Loader"

const PaymentSuccessPage = ({
  authError,
  loading,
  ReduxCreateLogin,
  registerToken,
  registerEmail,
}) => {
  const data = useStaticQuery(graphql`
    query PaymentSuccessPageQuery {
      banner: allFile(filter: { name: { eq: "login_1440x400" } }) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1440) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
            id
          }
        }
      }
    }
  `)
  const [formReady, setFormReady] = useState(false)
  const [token, setToken] = useState(null)
  const [email, setEmail] = useState(null)
  const [isDisabled, setIsDisabled] = useState(false)
  const [error, setError] = useState(null)
  //! - - - - - - - - - - - - - - - - - - - - -
  const location = useLocation()
  const { register, handleSubmit, errors } = useForm()

  useEffect(() => {
    if (registerToken && registerEmail) {
      setEmail(registerEmail)
      setToken(registerToken)
      setFormReady(true)
    } else {
      try {
        //! Check for token in query string
        const q = location.search ? queryString.parse(location.search) : null
        if (q.email && q.token) {
          setEmail(q.email)
          setToken(q.token)
          setFormReady(true)
        }
      } catch (error) {
        console.error(error)
      }
    }
  }, [registerEmail, registerToken, location])

  const onSubmit = async data => {
    try {
      setIsDisabled(true)
      const { password, passwordConfirm } = data
      if (password !== passwordConfirm) {
        setError("Passwords must match. Please try again.")
        return
      }
      ReduxCreateLogin({ email, password, token })
      setIsDisabled(false)
    } catch (error) {
      console.error(`Register Error: ${error}`)
      setError(
        `An error occurred and your information was not submitted. Please try again.`
      )
      setIsDisabled(false)
    }
  }

  const renderForm = () => {
    if (formReady) {
      return (
        <>
          <div className="row d-flex justify-content-center mt-5">
            <div className="col-md-8 mx-auto d-flex flex-column">
              <H1>Setup Your Login</H1>
              <H3>
                You have a limited amount of time to set your password so please
                complete it now.
              </H3>
            </div>
          </div>

          <div className="row">
            <div className="col-md-8 mx-auto mt-2">
              <hr style={{ borderTop: "1px solid #d41f1f" }} />
            </div>
          </div>

          <div className="row d-flex justify-content-center mt-2">
            <form onSubmit={handleSubmit(onSubmit)} className="mb-5">
              <Section className="col-12">
                <Label>Email</Label>
                <Input
                  type="text"
                  placeholder="Email"
                  name="email"
                  value={email}
                  disabled
                />
              </Section>
              <Section className="col-12">
                <Label>Password</Label>
                <Input
                  type="password"
                  placeholder="Password"
                  name="password"
                  ref={register({ required: "Password is required" })}
                />
                {errors.password && <Error message={errors.password.message} />}
              </Section>
              <Section className="col-12">
                <Label>Confirm Password</Label>
                <Input
                  type="password"
                  placeholder="Confirm Password"
                  name="passwordConfirm"
                  ref={register({
                    required: "Password confirmation is required",
                  })}
                />
                {errors.passwordConfirm && (
                  <Error message={errors.passwordConfimr.message} />
                )}
              </Section>

              {loading ? (
                <div className="col-12 mt-2 text-center">
                  <Loader />
                </div>
              ) : (
                <div className="col-12 text-center">
                  <Button
                    disabled={isDisabled}
                    onClick={handleSubmit(onSubmit)}
                  >
                    Create Login
                  </Button>
                </div>
              )}
              {authError && (
                <div className="col-12 mt-4 text-center">
                  <Error message={authError} />
                </div>
              )}
              {error && (
                <div className="col-12 mt-4 text-center">
                  <Error message={error} />
                </div>
              )}
            </form>
          </div>
        </>
      )
    }
    return (
      <>
        <div className="row d-flex justify-content-center mt-5">
          <div className="col-md-8 mx-auto d-flex flex-column">
            <H1>You are not authorized</H1>
          </div>
        </div>
      </>
    )
  }

  const banner = data.banner.edges[0].node.childImageSharp.fluid
  return (
    <Layout fluid={banner} alt={"Neil Hill Coaching"} height={300}>
      <div className="container-fluid">
        <SEO title="Client Login Setup" />
        {renderForm()}
      </div>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    registerEmail: state.auth.registerEmail,
    registerToken: state.auth.registerToken,
    authenticated: state.auth.authenticated,
    loading: state.auth.loading,
    authError: state.auth.authError,
  }
}
export default connect(mapStateToProps, actions)(PaymentSuccessPage)
